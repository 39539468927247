import './App.css';

function App() {
  return (
    <div className="landingPage">
      <Header />
    </div>
  );
}

const Header = () => {
  return (
    <header className="header">
      <h1 className="logo">LetMeListen.ai</h1>
      <button className="whatsappButton">
        <a href="https://wa.me/12522200251">Add on WhatsApp</a>
      </button>
    </header>
  );
};


export default App;
